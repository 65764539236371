
export default {
  name: 'OrganismSlider',
  props: {
    slidess: {
      required: false,
      type: Array,
      default: function () {
        return [
          {
            titles: {
              required: false,
              type: Array,
              default: function () {
                return []
              }
            },
            descriptions: {
              required: false,
              type: Array,
              default: function () {
                return []
              }
            },
            background: {
              required: false,
              type: String,
              default: function () {
                return '#5bb4e5'
              }
            }
          }
        ]
      }
    },

    images: {
      required: false,
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data: function () {
    return {
      visibleSlide: 0,
      direction: 'left'
    }
  },
  computed: {
    sliderLength() {
      if (this.images.length > 0) return this.images.length
      return this.slidess.length
    }
  },
  mounted() {},
  methods: {
    Previous: function () {
      this.visibleSlide = this.visibleSlide - 1 >= 0 ? this.visibleSlide - 1 : this.sliderLength - 1
      this.direction = 'right'
    },
    Next: function () {
      this.visibleSlide = this.visibleSlide + 1 < this.sliderLength ? this.visibleSlide + 1 : 0
      this.direction = 'left'
    }
  }
}
