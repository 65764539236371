import { render, staticRenderFns } from "./OrganismSidebarAccordion.vue?vue&type=template&id=5ab5056e&"
import script from "./OrganismSidebarAccordion.vue?vue&type=script&lang=js&"
export * from "./OrganismSidebarAccordion.vue?vue&type=script&lang=js&"
import style0 from "./OrganismSidebarAccordion.vue?vue&type=style&index=0&id=5ab5056e&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculesCommonMoleculeSidebarAccordionResults: require('/app/components/molecules/common/MoleculeSidebarAccordionResults.vue').default})
