
export default {
  name: 'OrganismImageSliderV2',
  props: {
    sliderData: {
      required: true,
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {
      activeSlider: this.sliderData.videos && this.sliderData.videos.length ? 'video' : 'image'
    }
  },
  computed: {
    visibleSlider() {
      return this.activeSlider
    },
    hasImages() {
      return this.sliderData.images ? this.sliderData.images.length > 0 : false
    },
    hasVideos() {
      return this.sliderData.videos ? this.sliderData.videos.length > 0 : false
    }
  },
  mounted() {
  },
  methods: {
    handlePhotoClick() {
      const photoButton = this.$el.querySelector('.photoButton')
      const videoButton = this.$el.querySelector('.videoButton')
      if (!photoButton.classList.contains('active')) {
        photoButton.classList.add('active')
        this.activeSlider = 'image'
        videoButton.classList.remove('active')
      }
    },
    handleVideoClick() {
      const videoButton = this.$el.querySelector('.videoButton')
      const photoButton = this.$el.querySelector('.photoButton')
      if (!videoButton.classList.contains('active')) {
        videoButton.classList.add('active')
        this.activeSlider = 'video'
        photoButton.classList.remove('active')
      }
    },
    isVisible(sliderType) {
      return sliderType === this.activeSlider
    }
  }
}
