
export default {
  name: 'OrganismTitleTextImage',
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  methods: {
    resourceUrl(url) {
      if (url.includes('://')) {
        return url
      }
      return `${this.$store.getters['base/cdnBase']}/${url}`
    }
  }
}
