
export default {
  name: 'OrganismParkingAccess',
  props: {
    parkingAccess: {
      required: true,
      type: Array,
      default: function () {
        return []
      }
    },
    imageUrl: {
      required: true,
      type: String,
      default: function () {
        return ''
      }
    }
  },
  data() {
    const showEntries = {}
    const showExits = {}
    this.parkingAccess.forEach((pa) => {
      showEntries[pa.slug] = false
      showExits[pa.slug] = false
    })
    return {
      animations: [],
      showEntries: showEntries,
      showExits: showExits
    }
  },
  computed: {
    cdnBase() {
      return this.$store.getters.cdnBase
    },
    assetUrl() {
      if (!this.imageUrl) return ''
      if (this.imageUrl.includes('://')) {
        return this.imageUrl
      }
      return `${this.cdnBase}/${this.imageUrl}`
    }
  },
  watch: {
    parkingAccess: function (items, oldItems) {
      this.destroyAllAnimations()
      const showEntries = {}
      const showExits = {}
      items.forEach((pa) => {
        showEntries[pa.slug] = false
        showExits[pa.slug] = false
      })
      this.showEntries = showEntries
      this.showExits = showExits
    }
  },
  methods: {
    handleAnimation(animation, id) {
      this.animations[id] = animation
    },
    stopAnimation(id) {
      if (!this.animations[id]) return
      this.animations[id].stop()
    },
    playEntries(id) {
      this.showEntries[id] = true
      this.showExits[id] = false
      delete this.animations[`${id}-exits`]
      if (!this.animations[`${id}-entries`]) {
        setTimeout(() => this.playEntries(id), 100)
        return
      }
      this.playAnimation(`${id}-entries`)
    },
    playExits(id) {
      this.showEntries[id] = false
      this.showExits[id] = true
      delete this.animations[`${id}-entries`]
      if (!this.animations[`${id}-exits`]) {
        setTimeout(() => this.playExits(id), 100)
        return
      }
      this.playAnimation(`${id}-exits`)
    },
    playAnimation(id) {
      this.resetAllAnimations()
      if (!this.animations[id]) return
      this.animations[id].setSpeed(1)
      this.animations[id].play()
      const { totalFrames, endFrame } = this.animations[id]
      this.animations[id].playSegments([0, endFrame || totalFrames], true)
    },

    pauseAnimation(id) {
      if (!this.animations[id]) return
      this.animations[id].pause()
    },

    resetAnimation(id) {
      if (!this.animations[id]) return
      this.animations[id].goToAndStop(0)
    },

    resetAllAnimations() {
      Object.keys(this.animations).forEach((key) => {
        this.resetAnimation(key)
      })
    },

    destroyAnimation(id) {
      if (!this.animations[id]) return
      this.animations[id].destroy()
    },

    destroyAllAnimations() {
      Object.keys(this.animations).forEach((key) => {
        this.destroyAnimation(key)
      })
    }
  }
}
