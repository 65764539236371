
export default {
  name: 'OrganismImageSlider',
  props: {
    sliderData: {
      required: true,
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {
      typeOf: 'image',
      carouselInitialized: false
    }
  },
  computed: {
    thumbData() {
      return this.typeOf === 'image' ? this.sliderData.images : this.sliderData.videos
    },
    brochureData() {
      return this.sliderData.brochure
    },
    hasImages() {
      return this.sliderData.images ? this.sliderData.images.length > 0 : false
    },
    hasVideos() {
      return this.sliderData.videos ? this.sliderData.videos.length > 0 : false
    }
  },
  beforeUpdate() {
    if (this.typeOf !== 'image' && !this.hasVideos) {
      this.typeOf = 'image'
    }
  },
  methods: {
    handleThumbChange(index) {
      this.$refs.slider.instance.trigger('to.owl.carousel', [index, 300])
    },
    resourceUrl(img) {
      if (img.includes('://')) {
        return img
      }
      return `${this.$store.getters.cdnBase}/${img}`
    },
    handlePhotoClick() {
      const photoButton = this.$el.querySelector('.photoButton')
      const videoButton = this.$el.querySelector('.videoButton')
      if (!photoButton.classList.contains('active')) {
        photoButton.classList.add('active')
        this.typeOf = 'image'
        videoButton.classList.remove('active')
      }
    },
    handleVideoClick() {
      const videoButton = this.$el.querySelector('.videoButton')
      const photoButton = this.$el.querySelector('.photoButton')
      if (!videoButton.classList.contains('active')) {
        videoButton.classList.add('active')
        this.typeOf = 'video'
        photoButton.classList.remove('active')
      }
    },
    handleCarouselInitialized(initialized) {
      this.carouselInitialized = initialized
    }
  }
}
