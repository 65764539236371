
export default {
  name: 'OrganismSidebarAccordion',
  props: {
    pinsInfo: [],
    sidebarTitle: {
      required: false,
      type: String,
      default: ''
    }
  },
  computed: {
    isMobileMenuOpen() {
      return this.$store.state.base.mobileAvailabilityMenuOpen
    }
  }
}
