import { render, staticRenderFns } from "./OrganismSlider.vue?vue&type=template&id=ba532184&"
import script from "./OrganismSlider.vue?vue&type=script&lang=js&"
export * from "./OrganismSlider.vue?vue&type=script&lang=js&"
import style0 from "./OrganismSlider.vue?vue&type=style&index=0&id=ba532184&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsCommonSlider2AtomArrowButtonSlider: require('/app/components/atoms/common/Slider2/AtomArrowButtonSlider.vue').default,MoleculesCommonMoleculeSliderSlide: require('/app/components/molecules/common/MoleculeSliderSlide.vue').default})
